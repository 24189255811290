import React from 'react';
import Layout from '../components/layout';

const PrivacyPolicyPage = () => {
  return (
    <Layout styles="privacy-policy" title="Privacy Policy">
      Privacy Policy Here
    </Layout>
  );
};

export default PrivacyPolicyPage;
